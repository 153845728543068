<template>
  <!-- hidden PageHeaderWrapper title demo -->
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form :form="form">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="通知标题"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <a-input
                v-model="formData.title"
                placeholder="请输入通知标题" />
            </a-form-item>
            <a-form-item
              label="通知类型"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <a-select v-model="formData.type" placeholder="请选择" style="width:180px">
                <a-select-option
                  :value="item.value"
                  v-for="item in menuList"
                  :selected="item.value == formData.type"
                  :key="item.id"
                >{{ item.description }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="浏览权限"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <a-radio-group name="radioGroup" :default-value="1" v-model="formData.level">
                <a-radio :value="1">
                  省级
                </a-radio>
                <a-radio :value="2">
                  市级
                </a-radio>
                <a-radio :value="3">
                  县级
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="通知内容"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
                <tinymce-editor ref="editor"
                                v-model="formData.content"
                                :disabled="disabled"
                                :base-url="baseUrl"
                                :language="language"
                                :skin="skin"
                                @onClick="onClick">
                </tinymce-editor>
            </a-form-item>
            <a-form-item
              label="附件"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <a-upload
                :multiple="true"
                :fileList="downloadFiles"
                :remove="handleDownloadFileRemove"
                :customRequest="downloadFilesCustomRequest"
              >
                <a-button> <a-icon type="upload" /> 上传文件 </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          :wrapperCol="{ span: 24 }"
          style="text-align: center"
        >
          <a-button htmlType="submit" type="primary" @click="submit">提交</a-button>
          <a-button style="margin-left: 8px" @click="back">返回</a-button>
        </a-form-item>
      </a-form>
    </a-card>
</template>

<script>
import moment from 'moment'
import { getMenuList, addNotice, getNoticeInfo, updateNotice,upload } from '@/api/basic/common'

import TinymceEditor from './Editor/tinymce-editor'

export default {
  components: {
      TinymceEditor
  },
  created () {
    this.getMenu()
    this.getInfo()
  },
  data () {
    return {
      // form
      form: this.$form.createForm(this),
      // 表单数据
      formData: {
        content: '',
        fileIds: [],
	      level: 1,
	      releaseDate: '',
	      releaseId: 1,
	      releaseManager: '',
	      releaseUnit: '',
	      title: '',
	      type: 1,
	      type_name: ''
      },
        //tiny  参数
        disabled: false,
        baseUrl: process.env.NODE_ENV === 'production' ? '' : '',
        language: 'zh_CN',
        skin: 'oxide',
        // 查询参数
      menuParam: {
        menu: '通知类型'
      },
      // 接口返回数据
      menuList: [],
      downloadFiles: []
    }
  },
  methods: {
      //鼠标单击的事件
      onClick(e, editor) {
          console.log('Element clicked')
          console.log(e)
          console.log(editor)
      },
    getInfo() {
      getNoticeInfo({ id: this.$route.query.id }).then(response => {
        this.formData.title = response.data.title
        this.formData.content = response.data.content
        this.formData.type = response.data.type
        this.formData.level = response.data.level
        if (response.data.fileRelaxs) {
          for (let i = 0; i < response.data.fileRelaxs.length; i++) {
            let file = this.fileFormatter(response.data.fileRelaxs[i])
            // 上传单个文件后，将该文件会先到a-upload组件的已上传文件列表中的操作
            this.downloadFiles.push(file)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 接口请求
    getMenu () {
      getMenuList(this.menuParam).then(response => {
        this.menuList = response.data.list
        this.formData.type = response.data.list[0].value
        this.formData.type_name = response.data.list[0].description
      })
    },
    // 操作方法
    submit () {

      var list = []
      for (let i = 0; i < this.downloadFiles.length; i++) {
        list.push(this.downloadFiles[i].uid)
      }
      this.formData.fileIds = list
      this.formData.releaseDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      this.formData.releaseId = localStorage.getItem('admin')
      this.formData.releaseManager = localStorage.getItem('userName')
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.formData.type === this.menuList[i].value) {
          this.formData.type_name = this.menuList[i].description
        }
      }
      if (this.$route.query.id === '0') {
        addNotice(this.formData).then(response => {
          if (response.code === 0){
            this.$message.info('添加成功')
            this.$router.back()
          } else {
            this.$message.error('添加失败')
          }
        })
      } else {
        var formList = this.formData
        formList.id = this.$route.query.id
        updateNotice(formList).then(response => {
          if (response.code === 0){
            this.$message.info('更新成功')
            this.$router.back()
          } else {
            this.$message.error('更新失败')
          }
        })
      }
    },
    back () {
      this.$router.back()
    },
    // handler
    handleChange ({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
      }
    },
    // 重写a-upload的文件上传处理方式
    downloadFilesCustomRequest (data) {
      this.saveFile(data)
    },  
    // 上传并保存文件
    saveFile (data){
      let fileData = new FormData()
      fileData.append("file", data.file)
      upload(fileData).then(response => {
        if (response.code === 0){
          let file = this.fileFormatter(response.data)
          // 上传单个文件后，将该文件会先到a-upload组件的已上传文件列表中的操作
          this.downloadFiles.push(file)
        } else {
          this.$message.error('上传失败')
        }
      })
    },
    // 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式（这个格式官方文档有给出的）
    fileFormatter(data) {
      let file = {  
        uid: data.id,  // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name: data.fileName,  // 文件名  
        status: 'done', // 状态有：uploading done error removed  
        response: '{"status": "success"}', // 服务端响应内容  
      }
      return file
    },
    // 没错，删除某个已上传的文件的时候，就是调用的这里
    handleDownloadFileRemove (file) {
      const index = this.downloadFiles.indexOf(file)
      const newFileList = this.downloadFiles.slice()
      newFileList.splice(index, 1)
      this.downloadFiles = newFileList
    }
  }
}
</script>
<style lang="less" scoped>
  .a-hover:hover {
    cursor: pointer;
  }
</style>
